<template>
  <el-dialog
    title="发布鉴定考试"
    :visible.sync="dialogFormVisible"
    width="800px"
    top="1%"
    @close="doClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item label="鉴定考试工种：" prop="compOccupationId">
         <el-select
          v-model="ruleForm.compOccupationId"
          placeholder="请选择"
          clearable
          filterable
          remote
           :remote-method="searchCompOccupation"
          size="small"
        >
          <el-option
            v-for="item in occupation"
            :key="item.compOccupationId"
            :label="item.showName"
            :value="item.compOccupationId"
          >
          </el-option>
        </el-select>
      </el-form-item>
       <el-form-item
                label="考试封面："
                prop="examPlanIconUrl"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.examPlanIconUrl || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
      <el-form-item label="报名时间：" prop="signUpTime">
        <el-date-picker
          size="small"
          v-model="ruleForm.signUpTime"
          type="datetimerange"
          style="width:100px"
          range-separator="至"
          start-placeholder="报名开始日期"
          end-placeholder="报名结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="计划人数：" prop="examPeople">
        <el-input-number v-model="ruleForm.examPeople"  @change="handleChange" :min="1" :max="999" size="small"></el-input-number>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label-width="30px" prop="examOnlinePay">
            <el-checkbox v-model="ruleForm.examOnlinePay">线上缴费</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="18" v-if="ruleForm.examOnlinePay">
          <el-form-item label="缴费金额：" prop="payAmount">
            <el-input-number v-model="ruleForm.payAmount"  @change="handleChange" :min="1" size="small"></el-input-number>&ensp;元
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-form-item>
        <div class="flexcc">
          <el-button @click="doClose()">取 消</el-button>
          <el-button class="bgc-bv" type="primary" @click="doOk"
            >确 定</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: "questionbankInformation",
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      //基本设置
      ruleForm: {
        compOccupationId: "", //考试工种
        signUpTime: "", //报名时间
        examPlanIconUrl:'',
        examOnlinePay:0,//线上缴费
        payAmount:null,//缴费金额
        examPeople: "", //考试人数
      },
      rules: {
        compOccupationId: [
          {
            required: true,
            message: "请选择鉴定考试工种",
            trigger: "change",
          },
        ],
        examPlanIconUrl: [
          {
            required: true,
            message: "请上传考试封面",
            trigger: "change",
          },
        ],
        signUpTime: [
          {
            required: true,
            message: "请选择报名时间",
            trigger: "change",
          },
        ],
        examPeople: [
          {
            required: true,
            message: "请输入考试人数",
            trigger: "blur",
          },
        ],
      },
      //考试工种
      occupation:[],
    
    };
  },
  computed: {},
  created() {
      this.getOccupation()
  },
  methods: {
     //考试封面
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.examPlanIconUrl = result.data.fileURL;
            this.ruleForm.examPlanIcon = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    showPopUp() {
      this.dialogFormVisible = true;
    },
    /* 鉴定考试工种 */
    getOccupation() {
        this.$post('/biz/evaluate/company/occupation/own',{}).then(ret => {
            this.occupation = ret.data.list || []
        }).catch(err => {
            return;
        })
    },
     /* 鉴定考试工种 --搜索 */
    searchCompOccupation(query) {
 this.$post('/biz/evaluate/company/occupation/own',{compOccupationName:query}).then(ret => {
            this.occupation = ret.data.list || []
        }).catch(err => {
            return;
        })
    },
    doOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const that = this;
          let parmar = {
            compOccupationId: that.ruleForm.compOccupationId,
            examPeople:that.ruleForm.examPeople,
            examPlanIcon:that.ruleForm.examPlanIcon,
            examStartTime:that.ruleForm.signUpTime[0],
            examEndTime:that.ruleForm.signUpTime[1],
            examOnlinePay:that.ruleForm.examOnlinePay
          };
          if(parmar.examOnlinePay){
            parmar.payAmount = that.ruleForm.payAmount
          }
           that
             .$post("/biz/evaluate/plan/update", parmar)
             .then((ret) => {
               that.doClose();
                this.$emit("getnewdatap");
            //    that.parentPsBack(ret.data);
               if (ret.status == 0) {
                 this.$message({
                   message: "保存成功",
                   type: "success",
                 });
               } else {
                 this.$message({
                   message: ret.message,
                   type: "error",
                 });
               }
             })
             .catch((err) => {
               return;
             });
        }
      });
    },
    doClose() {
      this.$refs["ruleForm"].resetFields();
      this.dialogFormVisible = false;
      this.ruleForm = {
        compOccupationId: "", //考试工种
        signUpTime: "", //报名时间
        examPlanIconUrl:'',
        examOnlinePay:0,//线上缴费
        payAmount:null,//缴费金额
        examPeople: "", //考试人数
      }
    },
    // 给父组件传参
    parentPsBack(id) {
      this.$emit("eventBus", id);
      this.doClose();
    },
  },
};
</script>
