<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试计划</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="考试工种" class="searchboxItem ci-full">
              <span class="itemLabel">考试工种:</span>
              <el-select
                v-model="compOccupationId"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in occupation"
                  :key="item.compOccupationId"
                  :label="item.showName"
                  :value="item.compOccupationId"
                >
                </el-option>
              </el-select>
            </div>
             <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handlAdd()"
              >新增评定计划</el-button
            >
            
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="考试工种"
                align="center"
                prop="compOccupationName"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试级别"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.examLevelStr || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="报名开始时间"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope"
                  >{{ scope.row.examStartTime | momentDate }}</template
                >
              </el-table-column>
              <el-table-column
                label="报名截止时间"
                align="center"
                show-overflow-tooltip
              >
              <template slot-scope="scope"
                  >{{ scope.row.examEndTimeStr | momentDate}}</template
                >
              </el-table-column>
              <!-- <el-table-column
                label="考试时间/地点"
                align="center"
                minWidth="330"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div style="text-align: left">
                    <p>
                      理论考试时间:{{ scope.row.examTheoryStart | moment }} -
                      {{ scope.row.examTheoryEnd | moment }}
                    </p>

                    <p>理论考试地点:{{ scope.row.examTheoryPlace }}</p>
                    <p>
                      实操考试时间:{{ scope.row.examPracticeStart | moment }} -
                      {{ scope.row.examPracticeEnd | moment }}
                    </p>

                    <p>实操考试地点:{{ scope.row.examPracticePlace }}</p>
                    <p>
                      综合评审时间:{{ scope.row.examReviewStart | moment }} -
                      {{ scope.row.examReviewEnd | moment }}
                    </p>

                    <p>综合评审地点:{{ scope.row.examReviewPlace || "--" }}</p>
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column
                label="发布状态"
                align="center"
                prop="examPublish"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.examPublish ? '已发布':'未发布' }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试人数"
                align="center"
                prop="examPeople"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.examPeople || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="审核通过人数"
                align="center"
                prop="examPeopleJoin"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.examPeopleJoin || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="已报人数"
                align="center"
                prop="examPeopleSign"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.examPeopleSign || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="280px"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="appraisalEdit(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="appraisalDelete(scope.row.planId)"
                      >删除</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      :disabled="scope.row.examPublish"
                      @click="appraisalPublish(scope.row.planId,
                          scope.row.examPublish)"
                      >发布</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="appraisalBindCourse(scope.row)"
                      >绑定课程</el-button
                    >
                    <el-button
                      type="text"
                      style="padding: 0 5px"
                      @click="appraisalBindQuestionBank(scope.row)"
                      size="mini"
                      >绑定题库</el-button
                    >
                    <el-button
                      type="text"
                      style="padding: 0 5px"
                      @click="appraisalLookInformation(scope.row)"
                      size="mini"
                      >查看报名信息</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="handleBatchImport(scope.row)"
                      >下载报名码</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="
                        appraisalStopSigningUp(
                          scope.row.planId,
                          scope.row.examStop
                        )
                      "
                      >{{
                        scope.row.examStop ? "开启报名" : "停止报名"
                      }}</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <appraisalExaminationPlanAdd
      ref="appraisalExaminationPlanAdd"
      @getnewdatap="getData"
      @subShows="subShows"
    />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import appraisalExaminationPlanAdd from "@/views/appraisalInstitution/AppraisalExamination/appraisalExaminationPlanAdd.vue"; //新增试题弹层
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "appraisalInstitution/AppraisalExaminationPlanList",
  components: {
    Empty,
    PageNum,
    appraisalExaminationPlanAdd,
  },
  mixins: [List],
  data() {
    return {
      compOccupationId: "", //考试工种
      ExaminationLevel: "", //考试级别
      ExaminationTime: "", //考试时间
      examTheoryPlace: "", //考试地点
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      occupation: [],
    };
  },
  created() {
    this.getOccupation();
  },
  computed: {},
  methods: {
    /* 鉴定考试工种 */
    getOccupation() {
      this.$post("/biz/evaluate/company/occupation/own", {})
        .then((ret) => {
          this.occupation = ret.data.list || [];
        })
        .catch((err) => {
          return;
        });
    },
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compOccupationId) {
        params.compOccupationId = this.compOccupationId;
      }
      if (this.ExaminationLevel) {
        params.examLevel = this.ExaminationLevel;
      }
      if (this.examTheoryPlace) {
        params.examTheoryPlace = this.examTheoryPlace;
      }
      if (this.ExaminationTime) {
        params.examTheoryStart = this.ExaminationTime[0];
        params.examTheoryEnd = this.ExaminationTime[1];
      }
      this.doFetch({
        url: "/biz/evaluate/plan/getEvaluatePlanList",
        params,
        pageNum,
      });
    },
    /* 新增 */
    handlAdd() {
      this.$refs.appraisalExaminationPlanAdd.showPopUp();
    },
    /* 编辑 */
    appraisalEdit(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalExaminationBox",
        query: {
          active: "first",
          stu: "edit",
          planId: row.planId,
        },
      });
      let json = JSON.stringify({
        planId: row.planId,
        examOccupationName: row.examOccupationName,
        examPeople: row.examPeople,
        examPeopleJoin: row.examPeopleJoin,
      });
      sessionStorage.setItem("appraisalJson", json);
    },
    /* 删除 */
    appraisalDelete(planId) {
      this.doDel({
        url: "/biz/evaluate/plan/delete",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { planId },
        },
      });
    },
    /* 绑定课程 */
    appraisalBindCourse(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalExaminationBox",
        query: {
          active: "second",
          stu: "edit",
           planId: row.planId,
        },
      });
      let json = JSON.stringify({
         planId: row.planId,
        examOccupationName: row.examOccupationName,
        examPeople: row.examPeople,
        examPeopleJoin: row.examPeopleJoin,
      });
      sessionStorage.setItem("appraisalJson", json);
    },
    /* 绑定题库 */
    appraisalBindQuestionBank(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalExaminationBox",
        query: {
          active: "three",
          stu: "edit",
           planId: row.planId,
        },
      });
      let json = JSON.stringify({
         planId: row.planId,
        examOccupationName: row.examOccupationName,
        examPeople: row.examPeople,
        examPeopleJoin: row.examPeopleJoin,
      });
      sessionStorage.setItem("appraisalJson", json);
    },
    /* 查看报名信息 */
    appraisalLookInformation(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalExaminationBox",
        query: {
          active: "four",
          stu: "edit",
          planId: row.planId,
        },
      });
      let json = JSON.stringify({
        planId: row.planId,
        examOccupationName: row.examOccupationName,
        examPeople: row.examPeople,
        examPeopleJoin: row.examPeopleJoin,
      });
      sessionStorage.setItem("appraisalJson", json);
    },
    /* 准考证模版 */
    appraisalAdmissionTemplate() {},
    /* 停止报名 */
    appraisalStopSigningUp(planId, examStop) {
      this.doDel({
        url: "/biz/evaluate/plan/stopSign",
        msg: examStop ? "你确定要重新开启报名吗？" : "你确定要停止考试报名吗？",
        ps: {
          type: "post",
          data: { planId: planId },
        },
      });
    },
    /* 发布 */
    appraisalPublish(planId, examPublish) {
      this.doDel({
        url: "/biz/evaluate/plan/publish",
        msg:  "你确定要发布吗？",
        ps: {
          type: "post",
          data: { planId: planId },
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
       // 下载二维码
    handleBatchImport(row) {
      let data = {
        planId: row.planId,
      };
      this.$post("/biz/evaluate/plan/getUnlimited", data)
        .then((res) => {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.message;
          link.setAttribute("download", "报名二维码");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
         
          return;
        });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
